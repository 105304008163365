import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInfoSkeleton from '../../../components/loaders/BasicInfoSkeleton';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useTranslation } from 'react-i18next';
import ListHeader from '../../../components/ListHeader';
import Button from '../../../components/buttons/Button';
import { IService, IServiceContact, IServiceInfo, IServiceUser } from '../../../modules/serviceProvider/types/interfaces';
import ModalContainer from '../../../components/ModalContainer';
import CreateUserForm from '../../../modules/serviceProvider/components/CreateUserForm';
import UsersTableContainer from '../../../modules/serviceProvider/components/UsersTableContainer';
import Cookies from 'js-cookie';
import { authContext } from '../../../store/context/authContext';
import CreateContactForm from '../../../components/CreateContactForm';
import ContactsTableContainer from '../../../components/ContactsTableContainer';
import CommonHighlights from '../../../modules/serviceProvider/components/CommonHighlights';
import ProductsTableContainer from '../../../modules/products/components/ProductsTableContainer';
import DistributorInfo from '../../../modules/distributors/components/DistributorInfo';
import ApplicationsTableContainer from '../../../modules/applications/components/ApplicationsTableContainer';
import { IApplication } from '../../../modules/applications/types/interfaces';
import { hasPermission } from '../../../utils/HelperFunctions';
import ContactInfo from '../../../modules/distributors/components/ContactInfo';

const ServiceDetails = () => {
  const [openModel, setOpenModel] = useState<boolean | number>(false);
  const { catchError } = useContext(authContext);
  const [contactId, setContactId] = useState<string | null>(null);
  const [contactData, setContactData] = useState<IServiceContact | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [userData, setUserData] = useState<IServiceUser | null>(null);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [serviceData, setServiceData] = useState<IService>();
  const [applicationsData, setApplicationsData] = useState<IApplication>();
  const [refetch, setRefetch] = useState<any>();
  const navigate = useNavigate();
  const userDataStr = localStorage.getItem('user_data');
  const userDataObj = userDataStr ? JSON.parse(userDataStr) : null;
  const serviceProviderID = userDataObj?.service_provider_id;
  const DistributorID = userDataObj?.distributor_id;

  useEffect(() => {
    id && Cookies.set('service_ID', id);
    return () => Cookies.remove('service_ID');
  }, [id]);

  const fixedData = useMemo(() => {
    return [
      {
        title: t('total_sales') + ' (' + t('sar') + ')',
        number: Number(serviceData?.total_sales).toLocaleString('en-US') || 0
      },
      {
        title: t('active_applications'),
        number: serviceData?.active_applications || 0
      },
      {
        title: t('limit') + ' (' + t('sar') + ')',
        number: Number(serviceData?.limit).toLocaleString('en-US') || 0
      },
      // {
      //     title: t("limited_used") + " (" + t("sar") + ")",
      //     number: Number(serviceData?.limit_used).toLocaleString('en-US') || 0,
      // },
      {
        title: t('limit_remaining') + ' (' + t('sar') + ')',
        number: (Number(serviceData?.limit) - Number(serviceData?.limit_used)).toLocaleString('en-US') || 0
      }
    ];
  }, [serviceData, t]);

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['service providers', id, i18n.language, refetch],
    queryFn: () => generalGet(`/service-providers/${id}?limit=100`),
    refetchOnWindowFocus: false,
    enabled: !!hasPermission(['service_providers.show'])
  });

  useEffect(() => {
    setContactData(serviceData?.contacts?.find((contact: IServiceContact) => contact.id === contactId)!);
  }, [contactId, serviceData]);

  useEffect(() => {
    serviceData?.users && serviceData?.users?.length > 0 && setUserData(serviceData?.users?.find((user: IServiceUser) => user.id === userId)!);
  }, [userId, serviceData]);

  useEffect(() => {
    const reqData = data?.data?.data?.serviceProvider;
    const appData = data?.data?.data?.applicationRequest;

    if (isSuccess) {
      setServiceData(reqData);
      setApplicationsData(appData.sort((a: any, b: any) => Number(b.id) - Number(a.id)));

      dispatch(
        setBreadCrumbsData({
          links: [
            { label: t('service_providers'), path: '/services' },
            { label: reqData.name[i18n.language], path: `/services/${id}` }
          ],
          page_title: reqData.name[i18n.language],
          serviceProviderId: id,
          editBtn: hasPermission(['service_providers.crud']) && { name: t('editServiceProvider'), path: `/services/create-service/${id}` }
        })
      );
    }
  }, [isSuccess, data, i18n, id]);

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  const contactTableHeaders = [
    { label: t('name') },
    { label: t('email') },
    { label: t('phone') },
    hasPermission(['service_providers.crud']) && { label: t('actions'), customClass: 'actions_col' }
  ];

  const usersTableHeaders = [
    { label: t('fullName') },
    { label: t('email') },
    { label: t('phone') },
    { label: t('role') },
    { label: t('status'), customClass: 'status_col' },
    hasPermission(['service_providers.crud']) && { label: t('actions'), customClass: 'actions_col' }
  ];

  const productsTableHeaders = [
    // { label: t("id"), customClass: "pro_id_col" },
    // { label: t("logo"), customClass: "pro_logo_col" },
    { label: t('serviceProvider'), customClass: 'registration_date_col, text-center' },
    { label: t('financialDuration'), customClass: 'text-center' },
    { label: `${t('profitRate')} (${t('year')})`, customClass: 'text-center profit' },
    { label: t('adminFees'), customClass: 'text-center' },
    // { label: t("offers"), customClass: "status_col" },
    { label: t('actions'), customClass: 'actions_col' }
  ];

  const applicationsTableHeaders = [
    { label: t('appNo') },
    { label: t('make&model') },
    { label: t('serviceProvider') },
    { label: t('Distributor') },
    { label: t('step') },
    { label: t('status') },
    { label: t('createdBy') },
    { label: t('lastUpdated') }
  ];

  if (isLoading)
    return (
      <div>
        <BasicInfoSkeleton />
        <BasicInfoSkeleton />
        <TableSkeleton columns={5} />
      </div>
    );

  return (
    <div className="page_content service_details_page">
      <DistributorInfo
        id={{
          route: `/services/create-service/${id}`,
          text: t('editServiceProvider')
        }}
        title={t('provider_details')}
        data={[
          { key: t('name'), value: data.data.data.serviceProvider.name[i18n.language], hasLogo: serviceData?.logo_url },
          { key: t('address'), value: serviceData?.address.city + ', ' + serviceData?.address.street }
        ]}
        // btnCustomClass="generalFixedButton"
      />
      <ListHeader customClass="row noMargin">
        <h2>{t('basicDetails')}</h2>
      </ListHeader>
      <CommonHighlights data={fixedData as IServiceInfo[]} />
      <div className="table-wrapper">
        <ListHeader customClass="row">
          <h2>{t('contacts')}</h2>
          <Button text={t('create_contacts')} onClick={() => setOpenModel(1)} />
        </ListHeader>
        <div className="contacts-cards-section">
          {serviceData?.contacts?.map((contact: IServiceContact) => (
            <>
              <ContactInfo
                key={contact.id}
                data={contact}
                setOpenModel={setOpenModel}
                setContactId={setContactId}
                deleteRoute={'/service-providers/service-provider-contact'}
                setRefetchData={setRefetch}
              />
            </>
          ))}
          {serviceData?.contacts && serviceData?.contacts?.length == 0 && <div>{t('No_contacts_found')}</div>}
        </div>
        {/* <ContactsTableContainer
          tableHeaders={contactTableHeaders}
          data={serviceData?.contacts}
          noDataMessage={t('No_contacts_found')}
          border
          setOpenModel={setOpenModel}
          setContactId={setContactId}
          contactId={contactId}
          setRefetchData={setRefetch}
          deleteRoute={'/service-providers/service-provider-contact'}
          lessColumns
        /> */}
      </div>
      {(hasPermission(['users.show']) || hasPermission(['owned-users.show'])) && (
        <div className="table-wrapper">
          <ListHeader customClass="row">
            <h2>{t('users')}</h2>
            {DistributorID == null && (hasPermission(['users.edit']) || (hasPermission(['owned-users.edit']) && serviceProviderID == id)) && (
              <Button text={t('create_users')} onClick={() => setOpenModel(2)} />
            )}
          </ListHeader>
          <UsersTableContainer
            tableHeaders={usersTableHeaders}
            data={serviceData?.users}
            noDataMessage={t('No_users_found')}
            border
            setOpenModel={setOpenModel}
            setUserId={setUserId}
            userId={userId}
            setRefetchData={setRefetch}
          />
        </div>
      )}
      {hasPermission(['products.show']) && (
        <div className="table-wrapper">
          <ListHeader customClass="row">
            <h2>{t('products')}</h2>
            <Button text={t('create_product')} onClick={() => navigate(`/products/create-product?sp=${id}`)} />
          </ListHeader>
          <ProductsTableContainer
            tableHeaders={productsTableHeaders}
            data={serviceData?.products?.map((item: any) => ({ ...item, service_provider: serviceData }))}
            noDataMessage={'No products found'}
          />
        </div>
      )}
      {hasPermission(['applications.get']) && (
        <div className="table-wrapper">
          <ListHeader customClass="row">
            <h2>{t('links.applications')}</h2>
          </ListHeader>
          <ApplicationsTableContainer tableHeaders={applicationsTableHeaders} data={applicationsData} noDataMessage={t('No_applications_found')} setRefetchData={setRefetch} />
        </div>
      )}
      {openModel == 1 && (
        <ModalContainer small>
          <CreateContactForm
            routeName={'/service-providers'}
            setContactId={setContactId}
            contactData={contactData}
            contactId={contactId}
            setContactData={setContactData}
            setRefetch={setRefetch}
            setOpen={setOpenModel}
            parentId={id}
          />
        </ModalContainer>
      )}
      {openModel == 2 && (
        <ModalContainer>
          <CreateUserForm
            setRefetch={setRefetch}
            setOpen={setOpenModel}
            serviceProviderId={id}
            setUserData={setUserData}
            userId={userId}
            setUserId={setUserId}
            userData={userData}
          />
        </ModalContainer>
      )}
    </div>
  );
};
export default ServiceDetails;
